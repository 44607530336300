import {getFormConfig} from "./configs/formConfig";
import API from '@api'
import {mapState} from "vuex";
import {getPermissionList} from "../../utils";

export default {
  data: function () {
    const formConfig = getFormConfig(this)

    return {
      formData: {}, //筛选条件
      ...formConfig
    }
  },
  computed:{
    ...mapState({
    })
  },
  mounted(){
    this.$store.dispatch('logoutData')
  },
  methods: {
    async submit() {
      let error = await new Promise(resolve => {
        this.$refs.filterForm.validate((success, error) => {
          if (success) {
            resolve();
          } else {
            resolve(error);
          }
        });
      });

      if (error) {
        return;
      }
      const params = {
        ...this.$refs.filterForm.formData,
        LANGUAGE:'CH_SC'
      }
      const { status,data } = await API.login(params);
      if(status === 200){
        this.$store.dispatch('getAllPageSearchItem', {TABLE_NAME: ''})
        this.$store.dispatch('getAllPageTableColumnItem', {TABLE_NAME: ''})
        window.sessionStorage.AUTH_ERROR = data.ERROR
        window.sessionStorage.AUTH_SESSION_ID = data.SESSION_ID
        window.sessionStorage.AUTH_STATUS = data.STATUS
        window.sessionStorage.USER_ID = this.$refs.filterForm.formData.USER_ID
        this.$store.dispatch('getUserPermissionData').then(({status,data})=>{
          if(status === 200){
            const {PERMISSION_LIST, MENU_DATA} = getPermissionList(data)
            window.sessionStorage.PERMISSION_LIST = JSON.stringify(PERMISSION_LIST)
            window.sessionStorage.MENU_DATA = JSON.stringify(MENU_DATA)
            if(this.$route.query.redirect && this.$route.query.redirect !== '/login'){
              if(this.$route.query.redirect.includes('redirect=')){
                return this.$router.push('/home');
              }
              this.$router.push(this.$route.query.redirect);
            }else{
              this.$router.push('/home');
            }
          }
        })

      }
    },
  }
}