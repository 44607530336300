export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [
      {
        key: "USER_ID",
        label: "用户名: ",
        placeholder: "请输入用户名",
        defaultUpper:true
      },
      {
        key: "PASSWORD",
        label: "密 码: ",
        attrs: {
          showPassword: true
        },
        nativeOn: {
          keyup: (event) => {
            if (event.keyCode === 13) {
              vm.submit();
            }
          }
        },
        placeholder: "请输入密码"
      },
      {
        key: "LANGUAGE",
        label: "语 言: ",
        type: "select",
        class: 'insertClass',
        options: async () => {
          return [

          ]
        },
        placeholder: "请选择语言"
      }
    ]
  }
}